// create device store
export default {
  namespaced: true,
  state () {
    return {
      WEB_TYPE: 'web',
      MINI_TYPE: 'phone',
      deviceWidth: 0,
      miniMaxWidth: 768,
      webMinWidth: 1000,
      webMaxWidth: 1440,
      isWeb: true
    }
  },
  getters: {
    isWebDevice (state) {
      return state.isWeb
    },
    isMiniDevice (state) {
      return !state.isWeb
    }
  },
  mutations: {
    setDevice (state, device) {
      state.isWeb = (device === state.WEB_TYPE)
    },
    setDeviceWidth (state, deviceWidth) {
      state.deviceWidth = deviceWidth
    }
  }
}
