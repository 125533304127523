import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { useStore } from 'vuex';
import useLocation from './composables/useLocation';
import TheLoading from '@/components/theLoading/index.vue';
import SystemDialog from './components/SystemDialog';

import { watch, onBeforeMount, onUnmounted } from 'vue';
export default {
  __name: 'App',
  setup(__props) {
    const store = useStore();
    const {
      getGeoLocation
    } = useLocation();
    const isMobile = () => /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    function windowResize() {
      const html = document.getElementsByTagName('html')[0];
      const winWidth = parseInt(getComputedStyle(html).width);
      store.commit('device/setDeviceWidth', winWidth);
      // current view width <= mini max width
      if (winWidth <= store.state.device.miniMaxWidth) {
        // set device flag: mini type
        store.commit('device/setDevice', store.state.device.MINI_TYPE);
      } else {
        // set device flag: web type
        store.commit('device/setDevice', store.state.device.WEB_TYPE);
      }
    }
    watch(() => store.state.device.deviceWidth, newWinwidth => {
      const appDom = document.getElementById('app');
      if (newWinwidth <= store.state.device.miniMaxWidth) {
        appDom.style['min-width'] = `100%`;
      } else {
        appDom.style['min-width'] = `${store.state.device.webMinWidth}px`;
      }
    });
    onBeforeMount(function () {
      const initialDeviceType = isMobile() ? 'phone' : 'web';
      store.commit('device/setDevice', initialDeviceType);
      // windowResize()
      // Obtain geographic location when the system
      // does not know the user's geographic location.
      if (!store.state.user.geoLocation) {
        getGeoLocation();
      }
      setTimeout(windowResize, 500);
      window.addEventListener('resize', windowResize);
    });
    onUnmounted(function () {
      window.removeEventListener('resize', windowResize);
    });
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_router_view), _createVNode(_unref(SystemDialog)), _createVNode(TheLoading)], 64);
    };
  }
};